import { FC } from "react";
import { Form, Input, Modal } from "antd";
import Service from "../../../services/Index";
import Util from "../../../helpers/Util";

type Props = {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
};

const AccountCreateModal: FC<Props> = ({ visible, onOk, onCancel }) => {
  const [form] = Form.useForm();

  const onOkModal = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          await Service.post("/v1/accounts", values);
          form.resetFields();
          onOk();
        } catch (e) {
          Util.showError(e);
        }
      })
      .catch((e) => {
        console.log("validateFields failed: ", e);
      });
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title="계정 등록"
      visible={visible}
      centered
      okText="등록"
      cancelText="취소"
      onOk={onOkModal}
      onCancel={onCancelModal}
      // destroyOnClose // NOTE: form ref 사용시 destroyOnClose 동작안함
    >
      <Form
        name="form"
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="계정 ID"
          name="id"
          rules={[
            {
              required: true,
              message: "계정 ID를 입력해주세요.",
            },
          ]}
        >
          <Input autoFocus />
        </Form.Item>

        <Form.Item
          label="계정 이름"
          name="name"
          rules={[
            {
              required: true,
              message: "계정 이름을 입력해주세요.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="이메일"
          name="email"
          rules={[
            {
              required: true,
              message: "이메일을 입력해주세요.",
            },
            {
              type: "email",
              message: "이메일 형식이 올바르지 않습니다.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="비밀번호"
          name="password"
          rules={[
            {
              required: true,
              message: "비밀번호를 입력해주세요.",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="비밀번호 확인"
          name="password2"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "확인을 위해 비밀번호를 한번 더 입력해주세요.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("입력하신 두 개의 비밀번호가 일치하지 않습니다.")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AccountCreateModal;
