import { Modal, notification } from "antd";
import moment, { Moment } from "moment";

let errorShown = false;

const Util = {
  isProduction: process.env.REACT_APP_ENV === "production",

  formatDateTime: (
    value: Moment | string | undefined | null,
    defaultValue: string = ""
  ) => {
    if (value === undefined || value === null) return defaultValue;
    if (typeof value === "string") {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return value.format("YYYY-MM-DD HH:mm:ss");
    }
  },

  parseErrorMessage: (err: any) => {
    let content = "";
    if (err.response && err.response.data) {
      const { data } = err.response;
      if (Array.isArray(data.message) && data.message.length > 0) {
        content = data.message[0];
      } else {
        content = data.message || data.error || "Unknown Error";
      }
    } else if (err.message) {
      content = err.message;
    } else {
      content = err;
    }

    return content;
  },

  showError: (err: any) => {
    if (errorShown) return;
    errorShown = true;

    Modal.error({
      content: Util.parseErrorMessage(err),
      centered: true,
      onOk: () => {
        errorShown = false;
      },
      onCancel: () => {
        errorShown = false;
      },
    });
  },

  notifyError: (err: any) => {
    notification["error"]({
      message: "Error",
      description: Util.parseErrorMessage(err),
    });
  },
};

export default Util;
