import { FC } from "react";
import { Col, Row } from "antd";

type Props = {
  size: "small" | "middle";
};

const AppLogo: FC<Props> = ({ size }) => {
  const width1 = size === "small" ? 52 : 70;
  const width2 = size === "small" ? 80 : 110;
  return (
    <Row justify="center" align="middle">
      <Col>
        <img src="/images/icon_hotel.svg" alt="logo" width={width1} />
      </Col>
      <Col style={{ marginLeft: 5 }}>
        <img src="/images/icon_logo.svg" alt="staygo" width={width2} />
      </Col>
    </Row>
  );
};

export default AppLogo;
