import axios from "axios";
import React, { useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { AuthService } from "./services/AuthService";

type AuthContextType = {
  user: any; // TODO: any -> user entity
  login: (username: string, password: string, callback: VoidFunction) => void;
  logout: (callback: VoidFunction) => void;
};

const AuthContext = React.createContext<AuthContextType>(null!);

export const useAuth = () => React.useContext(AuthContext);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const username = window.localStorage.getItem("username");
  const token = window.localStorage.getItem("token");

  const [user, setUser] = useState<any>(username);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const login = async (
    username: string,
    password: string,
    callback: VoidFunction
  ) => {
    const { accessToken } = await AuthService.login(username, password);
    if (accessToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      window.localStorage.setItem("token", accessToken);
      window.localStorage.setItem("username", username);
      setUser(username);
      callback();
    }
  };

  const logout = async (callback: VoidFunction) => {
    const { result } = await AuthService.logout();
    if (result === "ok") {
      axios.defaults.headers.common["Authorization"] = "";
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("username");
      setUser(null);
      callback();
    }
  };

  const value = { user, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}
