import { FC } from "react";
import { Layout } from "antd";

const Dashboard: FC = () => {
  const { Content } = Layout;

  return (
    <Layout style={{ padding: 20 }}>
      <Content style={{ padding: 20, backgroundColor: "white" }}>
        <div style={{ width: 300, height: 2300, backgroundColor: "white" }}>
          Dashboard
        </div>
      </Content>
    </Layout>
  );
};

export default Dashboard;
