import { FC } from "react";
import { Form, Input, Button, Row, Col, Tabs } from "antd";
import { useAuth } from "../auth";
import { useLocation, useNavigate } from "react-router-dom";
import AppLogo from "./AppLogo";
const { TabPane } = Tabs;

const Login: FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values: any) => {
    const { accountId, loginId, password } = values;
    const username = loginId + "@" + accountId;
    const from = location.state?.from?.pathname || "/";

    auth.login(username, password, () => {
      navigate(from, { replace: true });
    });
  };

  return (
    <Row align="middle" justify="center" style={{ height: "100vh" }}>
      <Col style={{ width: "80%", maxWidth: "400px" }}>
        <AppLogo size="middle" />

        <Tabs
          defaultActiveKey="1"
          type="card"
          centered
          style={{ marginTop: 20, marginBottom: 32 }}
        >
          <TabPane tab="사용자 로그인" key="1">
            <LoginForm onSubmit={onSubmit} />
          </TabPane>
          <TabPane tab="계정 로그인" key="2" disabled />
        </Tabs>
      </Col>
    </Row>
  );
};

type Props = {
  onSubmit?: (values: any) => void;
};

const LoginForm: FC<Props> = ({ onSubmit }) => {
  const onFinish = (values: any) => {
    onSubmit?.(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("LoginForm.onFinishFailed:", errorInfo);
  };

  return (
    <Form
      name="login"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      initialValues={{
        accountId: "staygo",
        loginId: "dev1",
        password: "1234",
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="계정 ID"
        name="accountId"
        rules={[
          {
            required: true,
            message: "계정 ID를 입력해주세요.",
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        label="사용자 ID"
        name="loginId"
        rules={[
          {
            required: true,
            message: "사용자 ID를 입력해주세요.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="비밀번호"
        name="password"
        rules={[
          {
            required: true,
            message: "비밀번호를 입력해주세요.",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 6,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          로그인
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
