import { FC, useState } from "react";
import { Layout, Menu } from "antd";
import { Route, Routes, useLocation } from "react-router-dom";
import Accounts from "./accounts/Accounts";

const Admin: FC = () => {
  const { Content, Sider } = Layout;
  const [siderBroken, setSiderBroken] = useState(false);
  const SIDER_WIDTH = 200;

  const onSiderBreakpoint = (broken: boolean) => {
    setSiderBroken(broken);
  };

  const location = useLocation();
  const pathnames = location.pathname.split("/");
  const selectedMenuKey = pathnames.length > 2 ? pathnames[2] : "";

  return (
    <>
      <Sider
        width={SIDER_WIDTH}
        style={{ position: "fixed", height: "100vh" }}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={onSiderBreakpoint}
      >
        <Menu
          theme="light"
          mode="vertical"
          defaultSelectedKeys={[selectedMenuKey || "accounts"]}
          style={{ height: "100%", paddingTop: 30 }}
        >
          <Menu.Item key="accounts">계정</Menu.Item>
        </Menu>
      </Sider>

      <Layout
        style={{ marginLeft: siderBroken ? 0 : SIDER_WIDTH, padding: 20 }}
      >
        <Content style={{ padding: 20, backgroundColor: "white" }}>
          <Routes>
            <Route index element={<Accounts />} />
            <Route path="accounts" element={<Accounts />} />
          </Routes>
        </Content>
      </Layout>
    </>
  );
};

export default Admin;
