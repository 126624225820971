import { FC, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "antd";
import Search from "antd/lib/input/Search";
import Service from "../../../services/Index";
import Util from "../../../helpers/Util";
import AccountCreateModal from "./AccountCreateModal";

const AccountList: FC = () => {
  const [items, setItems] = useState<any>([]);

  const fetchItems = async () => {
    const result = await Service.get("/v1/accounts");
    if (result && result.accounts) {
      setItems(result.accounts);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "150px",
    },
    {
      title: "이름",
      dataIndex: "name",
    },
    {
      title: "이메일",
      dataIndex: "email",
    },
    {
      title: "생성일",
      dataIndex: "createdAt",
      render: (text: string) => {
        return Util.formatDateTime(text);
      },
    },
  ];

  const [modalVisible, setModalVisible] = useState(false);

  const onClickCreate = () => {
    setModalVisible(true);
  };

  const onOkCreate = () => {
    setModalVisible(false);
    fetchItems();
  };

  const onCancelCreate = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          <Search disabled placeholder="" style={{ width: 200 }} />
        </Col>
        <Col>
          <Button type="default" onClick={onClickCreate}>
            계정 등록
          </Button>
        </Col>
      </Row>
      <Table dataSource={items} columns={columns} size="middle" rowKey="id" />

      <AccountCreateModal
        visible={modalVisible}
        onOk={onOkCreate}
        onCancel={onCancelCreate}
      />
    </>
  );
};

export default AccountList;
