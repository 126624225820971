import Service from "./Index";

export const AuthService = {
  login: (username: string, password: string) => {
    return Service.post("/v1/auth/tokens", { username, password });
  },
  logout: () => {
    // return Service.delete("/v1/auth/tokens");
    return Service.post("/v1/auth/logout");
  },
};
