import { FC } from "react";
import { Avatar, Col, Dropdown, Layout, Menu, Row } from "antd";
import "./App.less";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { SelectInfo } from "rc-menu/lib/interface";
import Dashboard from "./dashboard/Dashboard";
import Admin from "./admin/Admin";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { useAuth } from "../auth";
import AppLogo from "./AppLogo";

const App: FC = () => {
  const { Header } = Layout;
  const auth = useAuth();
  const navigate = useNavigate();

  const onSelectMenu = (info: SelectInfo) => {
    navigate(info.key);
  };

  const onClickLogout = () => {
    // NOTE:
    // Can't perform a React state update on an unmounted component 경고 방지를 위해 0.1초후 실행
    // Dropdown 항목(로그아웃) 선택시 해당 컴포넌트의 상태변화가 처리되기 전에 unmount 되면서 경고 발생
    setTimeout(() => {
      auth.logout(() => navigate("/"));
    }, 100);
  };

  const location = useLocation();
  const pathnames = location.pathname.split("/");
  const selectedMenuKey = pathnames.length > 1 ? pathnames[1] : "";

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={onClickLogout}>
        로그아웃
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 10,
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0px 10px 10px #0000000d",
        }}
      >
        <Row>
          <Col flex="140px">
            <AppLogo size="small" />
          </Col>
          <Col flex="auto">
            <Menu
              theme="light"
              mode="horizontal"
              defaultSelectedKeys={[selectedMenuKey || "dashboard"]}
              onSelect={onSelectMenu}
              style={{ marginLeft: 30, borderBottom: "0px" }}
            >
              <Menu.Item key="dashboard">대시보드</Menu.Item>
              <Menu.Item key="admin">어드민</Menu.Item>
            </Menu>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Dropdown overlay={menu} arrow>
              <a href="#!" onClick={(e) => e.preventDefault()}>
                <Avatar icon={<UserOutlined />} /> {auth.user} <DownOutlined />
              </a>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Layout style={{ marginTop: 64, minHeight: "calc(100vh - 64px)" }}>
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="dashboard/*" element={<Dashboard />} />
          <Route path="admin/*" element={<Admin />} />
        </Routes>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default App;
