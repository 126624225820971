import Title from "antd/lib/typography/Title";
import { FC } from "react";
import AccountList from "./AccountList";

const Accounts: FC = () => {
  return (
    <>
      <Title level={3}>계정</Title>
      <AccountList />
    </>
  );
};

export default Accounts;
