import axios from "axios";

console.log("REACT_APP_ENV: ", process.env.REACT_APP_ENV);
console.log("REACT_APP_API_ORIGIN: ", process.env.REACT_APP_API_ORIGIN);

const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;
const CONFIG = { headers: { "Content-Type": "application/json" } };

const Service = {
  get: async (endpoint: string, data?: any) => {
    const result = await axios(API_ORIGIN + endpoint, {
      params: data,
    });
    return result.data;
  },
  post: async (endpoint: string, data?: any) => {
    const body = JSON.stringify(data);
    const result = await axios.post(API_ORIGIN + endpoint, body, CONFIG);
    return result.data;
  },
  patch: async (endpoint: string, data?: any) => {
    const body = JSON.stringify(data);
    const result = await axios.patch(API_ORIGIN + endpoint, body, CONFIG);
    return result.data;
  },
  put: async (endpoint: string, data?: any) => {
    const body = JSON.stringify(data);
    const result = await axios.put(API_ORIGIN + endpoint, body, CONFIG);
    return result.data;
  },
  delete: async (endpoint: string) => {
    const result = await axios.delete(API_ORIGIN + endpoint);
    return result.data;
  },
};

export default Service;
